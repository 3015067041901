@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html,body
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

#root {
  flex-grow: 1;
}

#footer-div {
  padding-top: 30px;
}